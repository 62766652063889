/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "assets/css/font-awesome.min.css";

@font-face {
  font-family: 'VWHeadWebBold';
  src: url('assets/fonts/VWHeadWeb-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'VWHeadWebBoldItalic';
  src: url('assets/fonts/VWHeadWeb-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'VWHeadWebExtraBold';
  src: url('assets/fonts/VWHeadWeb-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'VWHeadWebLight';
  src: url('assets/fonts/VWHeadWeb-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'VWHeadWebRegular';
  src: url('assets/fonts/VWHeadWeb-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'VWHeadWebRegularItalic';
  src: url('assets/fonts/VWHeadWeb-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'VWTextWebBold';
  src: url('assets/fonts/VWTextWeb-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'VWTextWebBoldItalic';
  src: url('assets/fonts/VWTextWeb-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'VWTextWebLight';
  src: url('assets/fonts/VWTextWeb-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'VWTextWebRegular';
  src: url('assets/fonts/VWTextWeb-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'VWTextWebRegularItalic';
  src: url('assets/fonts/VWTextWeb-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AudiTypeBold';
  src: url('assets/fonts/AudiType-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'AudiTypeBoldItalic';
  src: url('assets/fonts/AudiType-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AudiTypeExtendedBold';
  src: url('assets/fonts/AudiType-ExtendedBold.ttf') format('truetype');
}

@font-face {
  font-family: 'AudiTypeExtendedBoldItalic';
  src: url('assets/fonts/AudiType-ExtendedBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AudiTypeExtendedItalic';
  src: url('assets/fonts/AudiType-ExtendedItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AudiTypeExtendedNormal';
  src: url('assets/fonts/AudiType-ExtendedNormal.ttf') format('truetype');
}

@font-face {
  font-family: 'AudiTypeItalic';
  src: url('assets/fonts/AudiType-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'AudiTypeNormal';
  src: url('assets/fonts/AudiType-Normal.ttf') format('truetype');
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  // position: fixed;
  // overflow: hidden;
  // max-width: 100%;
  // max-height: 100%;
  display: block;
}
